.audio-test-feedback-drawer .MuiDrawer-paper {
  background: #f5f5f5 !important;
}

.audio-test-feedback-drawer .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(3px);
}

.audio-test-feedback-drawer .listen-to-recording-text {
  color: #797B7C;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  letter-spacing: -0.38px;
}

.audio-test-feedback-drawer .listen-to-recording-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.audio-test-feedback-drawer .button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
}
