.feedback .MuiDialog-paper {
  border-radius: 12px !important;
  margin: 14px;
  width: 100%;
}

.feedback-dialog-title .MuiDialogTitle-root {
  padding: 14px 16px 10px 16px !important;
}

.feedback .feedback-field {
  width: 100%;
  padding-bottom: 0;
  border: none;
  outline: none;
  overflow: hidden;
  resize: none;
}

.feedback .MuiDialogContent-root {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
}

.feedback .MuiButton-startIcon {
    margin-right: 10px;
}

.rating .MuiRating-root{
    font-size: 1.3rem;
}

.rating .MuiSvgIcon-root {
    padding-right: 6px;
}

.rating .MuiRating-iconFilled {
    color: #faaf00;
}

.rating .MuiRating-iconEmpty {
    color: #1C1B1F;
}

.feedback .custom-emoji-radio-group{
  display: flex;
  justify-content: space-between;
  font-family: Open Sans;
  margin-top: 8px;
}