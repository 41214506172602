.timer-icon-container {
  text-align: center;
  margin: 5% 0px 5%;
}

.deplay-response-title {
  color: #000;
  text-align: center;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.38px;
  margin: 0px 14% 5px;
}

.deplay-response-desc {
  color: #000;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.266px;
  margin: 0px 12% 30px;
}
