.not-found-page {
  text-align: center;
  background: #2573d9;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.not-found-page-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;
}

.error-code-not-found {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1rem;
}

.error-message-not-found {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
}

.error-page-not-found-button {
  color: #fff;
  text-transform: capitalize;
  border-color: #fff;
  border-radius: 12px;
}
