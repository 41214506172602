.text-resize-slider .MuiSlider-mark {
    background-color: #A1A1A1! important;
    height: 8px! important;
    width: 1px! important;
}

.text-resize-slider .MuiSlider-rail{
    height: 2px! important;
}

.text-resize-slider .MuiSlider-track{
    border: 0px! important;
    height: 0px! important;
}

.text-resize-slider .MuiSlider-root{
    color: #A1A1A1! important;
}

.text-resize-slider .MuiSlider-thumb{
    color: #1976d2;
}

.text-resize-slider .MuiSlider-markActive {
    opacity: 1px! important;
    background-color: 'currentColor'! important;
}

.text-resize-slider .MuiSlider-markLabel{
    top: 15px! important;
}

.text-resize-slider .MuiSlider-markLabel[data-index="0"]{
    font-size: 16px! important;
}

.text-resize-slider .MuiSlider-markLabel[data-index="2"]{
    font-size: 32px! important;
    color: rgba(0, 0, 0, 0.87)! important;
}

.text-size .MuiTypography-root{
    margin-left: -15px! important;
    margin-top: 7px! important;
    margin-bottom: 20px! important;
}