.instruction-heading {
  text-align: center;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.228px;
  margin-top: 12px;
}

.container .MuiSvgIcon-root {
    font-size: 1.2rem! important;
}