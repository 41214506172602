.mic-working-fine-container-icon {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 26% 0px 0px;
}

.yay-text {
  color: #373737;
  text-align: center;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.38px;
  margin: 14px 0px 14px;
}

.mic-working-desc {
  color: #373737;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.266px;
  margin: 0px 15% 10%;
}
