.student-teacher-img {
  height: 34%;
  width: 90%;
  margin-top: -7%;
  margin-bottom: -3%;
}

.heading-container {
  text-align: center;
  padding: 2px 0px 0px 0px;
}

.welcome-orf-text {
  color: #373737;
  font-family: Open Sans;
  font-size: 36px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.684px;
}

.teaching-companion-text {
  color: #909090;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.304px;
}

.lets-start-button {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding-bottom: 16px;
  padding-top: 10px;
  background: #fff;
}

.lets-start-button .MuiButton-root {
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.25) !important;
}

.about-container {
  margin: 18px 16px 30px 24px;
}

.about {
  color: #373737;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.304px;
}

.about-desc {
  color: #969696;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.266px;
}

.how-it-works-container {
  margin: 0px 24px 80px 24px;
}

.how-it-works-text {
  color: #373737;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.304px;
  margin-bottom: 18px;
}

.instruction-container {
  border-radius: 11px;
  background: rgba(205, 223, 247, 0.44);
  padding: 16px 20px 24px 30px;
}
