@keyframes rocket-launching {
    0% {
      /* background: red; */
      left: 0px;
      top: 800px;
    }
    25% {
      /* background: green; */
      left: 0px;
      top: 100px;
    }
    100% {
      /* background: red; */
      /* left: 0px;
      top: 0px; */
    }
  }
  