.onboarding-drawer .MuiDrawer-paper {
  background: #2573D9 !important;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background-color: rgba(0, 0, 0, -0.5);
}

.onboarding-drawer .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(3px);
}

.onboarding-drawer .check-microphone-quality-text {
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.342px;
  margin-left: 32px;
    margin-right: 50px;
  margin-top: 20px;
}

.onboarding-drawer .audio-test-instruction-text {
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  letter-spacing: -0.266px;
  margin-left: 32px;
    margin-right: 50px;
  margin-top: 10px;
}

.onboarding-drawer .MuiDrawer-modal{
    background-color: red;
}

.onboarding-drawer .MuiButton-root {
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.25)! important;
}

.onboarding-drawer .MuiSvgIcon-root {
  font-size: 1.65rem! important;
}
