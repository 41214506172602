.img-container {
  text-align: center;
  margin: 44px 0px 0px;
}

.out-of-chances-text {
  color: #373737;
  text-align: center;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.38px;
  margin: 12px 0px 10px;
}

.out-of-chances-desc {
  color: #373737;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.266px;
  margin: 0px 26px 11px;
}

.impact-the-results-text {
  color: #373737;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%; /* 16.56px */
  letter-spacing: -0.228px;
  margin: 0px 4% 17px;
}
