.audio-size-short-error .MuiDialog-paper {
    border-radius: 12px! important;
    width: 100%! important;
    margin: 20px;
}

.audio-size-short-error .MuiDialogTitle-root {
    padding: 8px 24px 0px 24px;
}

.audio-size-short-error .MuiDialogContent-root {
    padding: 0px 16px 15px 16px
}