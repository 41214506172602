@keyframes blinking {
    0% { opacity: 1; }
    10% { opacity: 0.9; }
    15% { opacity: 0.8; }
    20% { opacity: 0.7; }
    25% { opacity: 0.6; }
    30% { opacity: 0.5; }
    35% { opacity: 0.4; }
    40% { opacity: 0.3; }
    45% { opacity: 0.2; }
    50% { opacity: 0.1; }
    55% { opacity: 0; }
    60% { opacity: 0.1; }
    65% { opacity: 0.2; }
    70% { opacity: 0.3; }
    75% { opacity: 0.4; }
    80% { opacity: 0.5; }
    85% { opacity: 0.6; }
    90% { opacity: 0.7; }
    95% { opacity: 0.8; }
    100% { opacity: 0.9; }
}