.saveResult .MuiDialog-paper {
  border-radius: 12px !important;
  width: 100%;
}

.saveResult .MuiDialogContent-root {
  padding: 0px 14px 15px 14px;
}

.saveResult .MuiDialogTitle-root {
  padding: 0px 0px !important;
  text-align: center;
}

.saveResult .MuiPaper-root {
  margin: 32px 14px;
}

.saveResult .recording-saved-text {
  color: #000;
  text-align: center;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.38px;
  padding: 0px 0px 2%;
}

.saveResult .you-can-assess-next-student-text {
  color: #373737;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.266px;
  padding: 0px 0px 3%;
}
