.audio-slider .MuiSlider-thumb {
    height: 10px! important;
    width: 10px! important;
    color: #2573D9! important;
    transition: left 0.5s;
}

.audio-slider .MuiSlider-rail{
    color: #373737! important;
    opacity: 1! important;
    height: 3px;
}

.duration{
    color: #373737! important;
    font-family: Open Sans;
    font-size: 14px;
}

.audio-player-icon .MuiSvgIcon-root{
    padding: 0px;
    margin: 0px;
    color: #373737! important;
}

.audio-slider .MuiSlider-root{
    padding: 4px 0px! important;
}

.audio-slider .MuiSlider-track{
    height: 2px! important;
    color: #2573D9! important;
}