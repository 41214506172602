.check-microphone-quality-text {
  color: #373737;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.342px;
  margin-left: 28px;
  margin-top: -10px;
}

.speak-the-following-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 16px;
}

.speak-the-following-text {
  color: #848484;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.342px;
  margin-left: 10px;
  margin-top: -5px;
}

.text-to-read-container {
  width: 100%;
  height: 70vh;
  box-sizing: border-box;
  overflow: hidden scroll;
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-to-read {
  font-size: 10vw;
  text-overflow: ellipsis;
  text-size-adjust: inherit;
  padding-bottom: 350px;
  margin-top: 18px;
  font-family: "Open Sans";
  color: rgb(55, 55, 55);
  line-height: 150%;
  letter-spacing: -0.76px;
}

.divider {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 5;
  background: white;
  border-top: 1px solid rgb(198, 198, 198);
  box-shadow: 0px -50px 50px rgba(252, 252, 247, 0.9);
}

.timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 16px;
}

.recording-dot {
  width: 10px;
  height: 10px;
  background-color: #e70000;
  border-radius: 50%;
  margin-right: 8px;
}

.timer {
  color: #000;
  font-size: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.38px;
}

.tap-button-instruct-text {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 18px;
  color: #98b0d0;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.304px;
}
