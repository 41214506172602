.audio-quality-poor-desc {
  color: #373737;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.266px;
  text-align: center;
  margin: 0px 14px 0px;
}

.try-again-another-device-desc {
  color: #373737;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.266px;
  text-align: center;
  margin: 0px 12px 10px;
}

.chances-left-text {
  color: #8e8e8e;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.19px;
  text-align: center;
  margin: 6px 12px 22px;
}
